.navbar {
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: rgb(30, 30, 30);
}

.logo {
  object-fit: cover;
  height: 3rem;
  width: 4rem;
}

.desktopMenuListItem {
  margin: 1rem;
  cursor: pointer;
}

.desktopMenuListItem:hover {
  color: yellow;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid yellow;
}

.desktopMenuBtn {
  background: white;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 2rem;
}

.desktopManuImg {
  object-fit: cover;
  height: 1rem;
  width: 1rem;
  margin: 1rem;
}

.active {
  color: yellow;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid yellow;
}
